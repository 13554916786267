.details-container {
  max-width: 1200px;
  margin: 16px auto;
  .main-img-box {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 0px;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      margin-top: 29px;
    }

    .info {
      margin: 1rem 2rem 1rem 1rem;
      height: 100%;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 4rem;
      @media (max-width: 768px) {
        width: auto;
        margin: 0 2rem 0 1rem;
      }
      .name {
        font-size: 1.4rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      .description {
        margin-bottom: 1rem;
        .header {
          font-size: 0.9rem;
          font-weight: 600;
          margin-bottom: 5px;
        }
      }
      .role {
        margin-bottom: 2rem;
        .header {
          font-size: 0.9rem;
          font-weight: 600;
          margin-bottom: 5px;
        }
      }
      .to-about-box {
        background-color: white;
        width: 160px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        margin-top: 1rem;
        border: 2px solid black;
        font-size: 1.2rem;
        letter-spacing: 4px;
        font-family: "GOODDP";
        cursor: pointer;
        box-shadow: 5px 5px 0 -2px white, 5px 5px 0 0px black;
        transition: 0.2s all ease-out;
        color: black;
        @media (max-width: 768px) {
          letter-spacing: 3px;
          padding: 0 0.6rem;
          margin: 2rem auto 0;
          font-size: 1rem;
          height: 40px;
          box-shadow: 3px 3px 0 -2px white, 3px 3px 0 0px black;
        }
        &:hover {
          box-shadow: 0 0 0 -2px white, 0 0 0 0px black;
        }
        a {
          text-decoration: none;
          color: black;
        }
      }
    }
  }
  .video-box {
    display: flex;
    justify-content: center;
    .iframe-video {
      margin-top: 5rem;
      @media (max-width: 768px) {
        margin-top: 1rem;
      }
      iframe {
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .images-box {
    .sub-img-box {
      margin: 2rem 4rem;
      @media (max-width: 768px) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
      img {
        width: 100%;
      }
    }
  }
}
