@import "./styles/colors";
@import "./components/navbar/navbar";
@import "./components/splash/splash";
@import "./components/navbar/navbar-sub";
@import "./components/projects/projects";
@import "./components/details/details";
@import "./components/about/about";
@import "./components/footer/footer";
@import "./components/widget/widget";
@import "./components/about/form2";

body {
  margin: 0;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// @font-face {
//   font-family: "makiirclehand";
//   src: url(./fonts/makiirclehand.woff2) format("woff2");
//   font-display: block;
// }

// old font, the one I've picked
@font-face {
  font-family: "GOODDP";
  src: local("GOODDP.TTF"), url(./fonts/GOODDP.TTF) format("truetype");
  font-display: block;
}

// karena picked 2022
@font-face {
  font-family: "antique";
  src: local("antique.woff2"), url(./fonts/antique.woff2) format("woff2");
  // font-display: block;
}
@font-face {
  font-family: "antique-light";
  src: local("antique1.woff2"), url(./fonts/antique1.woff2) format("woff2");
  // font-display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 100vh;
}

button:focus {
  outline: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}
