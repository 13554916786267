.projects-container {
  max-width: 1200px;
  margin: 8px auto 0;
  .project-container {
    .img-box {
      position: relative;
      margin: 1.5rem;
      img {
        width: 100%;
      }
      .content-box {
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0;
        width: 100%;
        background: black;
        height: 100%;
        z-index: 1;
        overflow: hidden;
        transition: all 0.5s ease-out;
        @media (max-width: 768px) {
          display: none;
        }
        &:hover {
          opacity: 0.9;
        }
        .content {
          margin: 2rem;
          color: white;
          .name {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 1.4rem;
            letter-spacing: 1px;
          }
          .info {
            font-size: 1.2rem;
            letter-spacing: 1px;
          }
        }
      }
      .bottom-box {
        display: none;
        margin-top: 5px;
        color: black;
        @media (max-width: 768px) {
          display: block;
        }
        .name {
          font-weight: 700;
          margin-bottom: 10px;
          font-size: 1.1rem;
        }
        .see-more-box {
          display: flex;
          align-items: center;
          color: black;
          .see-more {
            font-size: 0.8rem;
          }
          .icon {
            margin-left: 5px;
            height: 1rem;
            width: 1rem;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
