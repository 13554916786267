.about-container {
  max-width: 1200px;
  margin: 0 auto 200px;
  @media (max-width: 768px) {
    margin-bottom: 150px;
  }
  .img-container {
    margin-top: 29px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .info-box {
      max-width: 800px;
      display: flex;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .left-box {
        width: 40%;
        font-size: 2.7rem;
        font-weight: 800;
        padding: 2rem 1rem 0 0;
        @media (max-width: 768px) {
          width: auto;
          padding-bottom: 2rem;
          padding-top: 0;
          padding-left: 1rem;
        }
        .one {
          display: flex;
          span {
            color: $mainBlue;
            animation: changeColor 20s infinite linear;
            padding-left: 8px;
            animation-delay: 1s;
          }
        }
      }
      .right-box {
        width: 60%;
        padding: 0 0 0 1rem;
        @media (max-width: 768px) {
          width: auto;
          padding-right: 1rem;
        }
        .intro {
          font-size: 1.1rem;
          font-weight: 500;
          line-height: 1.6;
          text-align: start;
          .sub-intro {
            margin-top: 1rem;
          }
        }
        .resume-box {
          background-color: white;
          width: 140px;
          font-weight: 300;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          margin: 2rem 0 0;
          border: 2px solid black;
          font-size: 1.2rem;
          letter-spacing: 1px;
          font-family: "antique-light";
          cursor: pointer;
          box-shadow: 5px 5px 0 -2px white, 5px 5px 0 0px black;
          transition: 0.2s all ease-out;
          @media (max-width: 768px) {
            letter-spacing: 1px;
            padding: 0 0.6rem;
            margin: 2rem auto 0 0;
            font-size: 1rem;
            height: 40px;
            box-shadow: 3px 3px 0 -2px white, 3px 3px 0 0px black;
          }
          &:hover {
            box-shadow: 0 0 0 -2px white, 0 0 0 0px black;
          }
          a {
            text-decoration: none;
            color: black;
          }
        }
      }
    }
    .clients-container {
      margin-top: 180px;
      max-width: 800px;
      @media (max-width: 768px) {
        padding: 0 1rem;
      }
      .section-title {
        text-align: center;
        color: grey;
        margin-bottom: 20px;
      }
      .clients-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .client-container {
          width: 25%;
          margin: 1rem 0 1rem;
          display: flex;
          justify-content: center;
          @media (max-width: 768px) {
          }
          .client-box {
            width: 50%;
            @media (max-width: 768px) {
              width: 75%;
            }
            a {
              color: grey;
              font-weight: 700;
              transition: all 0.2s ease;
              width: 100%;
              @media (max-width: 768px) {
                font-size: 0.9rem;
              }
              &:hover {
                color: black;
              }
              .image-box {
                width: 100%;
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes changeColor {
  0% {
    color: $mainBlue;
  }

  20% {
    color: $mainBlue;
  }

  25% {
    color: $mainYellow;
  }

  45% {
    color: $mainYellow;
  }

  50% {
    color: $mainGreen;
  }

  70% {
    color: $mainGreen;
  }

  75% {
    color: $mainSalmon;
  }

  95% {
    color: $mainSalmon;
  }

  100% {
    color: $mainBlue;
  }
}
