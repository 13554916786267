.navbar-sub-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  .sub-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem 0 2rem;
    @media (max-width: 768px) {
      margin: 0.5rem 0 1rem;
    }
    .category {
      display: block;
      padding: 0;
      background-color: white;
      width: 120px;
      font-weight: 300;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      margin: 0 1rem;
      border: 2px solid black;
      font-size: 1.1rem;
      letter-spacing: 1px;
      font-family: "antique-light";
      cursor: pointer;
      box-shadow: 5px 5px 0 -2px white, 5px 5px 0 0px black;
      transition: 0.3s box-shadow ease-out;
      will-change: box-shadow;
      @media (max-width: 768px) {
        width: auto;
        letter-spacing: 1px;
        padding: 0 0.6rem;
        margin: 0 0.4rem;
        font-size: 0.8rem;
        height: 36px;
        box-shadow: 3px 3px 0 -2px white, 3px 3px 0 0px black;
      }
      &:hover {
        box-shadow: 0 0 0 -2px white, 0 0 0 0px black;
      }
    }
    a {
      text-decoration: none;
      color: black;
    }
    .num2 {
      width: 140px;
      margin: 0 2rem;
      @media (max-width: 768px) {
        width: 100px;
        margin: 0 1rem;
        font-size: 0.8rem;
        letter-spacing: 3px;
      }
    }
    .PACKAGING {
      @media (max-width: 768px) {
        padding: 0 0.3rem;
      }
    }
    .BRANDING {
      @media (max-width: 768px) {
        padding: 0 0.3rem;
      }
    }
  }
}
