.widget-container{
    position:fixed;
    bottom:40px;
    right:30px;
    z-index: 5;
    transition: opacity 0.2s ease-out;
    @media(max-width: 768px) {
        bottom:40px;
        left:30px;
    }
    button{
        border:0;
        padding:0;
        margin:0;
        background: transparent;
        cursor:pointer;

    }
    .widget-box{
        opacity:0;
        transition: opacity 0.5s ease-out;
        .icon{
            width:55px;
            height:55px;
            svg{
                width:100%;
                height:100%;
                color:$mainBlack;
            }
        }
    }
    .toggle{
        opacity: 0;
    }
}