.footer-container {
  width: 100%;
  background-color: $mainBlack;
  color: $darkWhite;
  margin-top: 100px;
  .footer-box {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    .left {
      padding-left: 1.5rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        align-items: center;
        justify-content: center;
        padding-left: 0;
        width: 100%;
      }
      .date {
        margin-bottom: 5px;
        font-size: 1rem;
      }
      .message {
        font-size: 1rem;
        margin-bottom: 60px;
        @media (max-width: 768px) {
          margin-bottom: 80px;
        }
      }
      .built-by-wrapper {
        z-index: 11;
        @media (max-width: 768px) {
          width: 70%;
          text-align: center;
        }
        .line {
          height: 0.5px;
          width: 100px;

          margin-bottom: 8px;
          background-color: $darkWhite;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
        a {
          color: $darkWhite;
          transition: all 0.3s ease;
          &:hover {
            color: white;
          }
        }
        .built-by-box {
          display: flex;
          align-items: center;
          .text {
            margin-right: 8px;
          }
          .image-box {
            width: 16px;
            height: 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .right {
      width: 50%;
      text-align: end;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      padding-right: 1.5rem;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding-right: 0;
        width: 100%;
      }
      .contact-box {
        @media (max-width: 768px) {
          margin-bottom: 30px;
        }
      }
      .social-box {
        margin-top: 30px;
        display: flex;
        @media (max-width: 768px) {
          align-items: center;
          justify-content: center;
          margin-top: 0;
          margin-bottom: 30px;
        }
        .social {
          margin-left: 10px;
          a {
            color: $darkWhite;
            transition: 0.3s all ease;
            &:hover {
              color: white;
            }
            .icon {
              width: 45px;
              height: 45px;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .contact-box {
        @media (max-width: 768px) {
          text-align: center;
          margin-bottom: 40px;
        }
        a {
          font-size: 1rem;
          color: $darkWhite;
          transition: 0.3s all ease;
          &:hover {
            color: white;
          }
        }
        .resume-box {
          font-size: 1rem;
          margin-top: 8px;
        }
        .about-box {
          margin-top: 5px;
        }
      }
    }
  }
}
