.navbar-container {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  .logo-box {
    font-size: 3rem;
    font-weight: 500;
    padding: 1.2rem 0;
    height: auto;
    @media (max-width: 768px) {
      font-size: 2.4rem;
    }
    a {
      text-decoration: none;
      color: black;
      font-family: "antique";
      letter-spacing: 1.5px;
      .img-box {
        width: 260px;
        img {
          width: 100%;
        }
      }
    }
  }
  .links-box {
    display: flex;
    justify-content: space-between;
  }
}
